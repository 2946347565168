import { Box, Code, Divider, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { Link as Clink } from "@chakra-ui/react"
import * as React from "react"
import { customPre } from './layout.module.css'

const CustomParagraph = props => <Text my={2}  fontSize={['sm', null, 'md', 'lg']} {...props} />
const CustomHeading2 = props => <Heading mt={10} as='h2' size={['xl', '2xl']} {...props}/>
const CustomHeading3 = props => <Heading mt={8} as='h2' size='lg' {...props}/>
const CustomHr = () => <Divider my={[7, 10]} />
const CustomPre = props => <Box mx={5} my={5} className={customPre} {...props} />
const CustomStrong = props => <Code bg='custom.navbar' color='custom.text' p='0px 4px' border='solid 1px' borderColor='custom.border' borderRadius='5px' fontSize={['xs', 'sm', null]} {...props} />
const CustomUl = props => <UnorderedList {...props} ml={16} mb={5} />
const CustomLi = props => <ListItem {...props} />
const CustomSpan = props => <Box mb={5} {...props} />

const CustomLink = props => <Clink fontWeight='bold' color='custom.orange' {...props} isExternal/>

const MDXComponents = {
	p: CustomParagraph,
	h2: CustomHeading2,
	h3: CustomHeading3,
	hr: CustomHr,
	pre: CustomPre,
	strong: CustomStrong,
	ul: CustomUl,
	li: CustomLi,
	span: CustomSpan,
	a: CustomLink,
}

export default MDXComponents