import "./fonts.css";
import { extendTheme } from "@chakra-ui/react";
const theme = {
	styles:{
		global: {
			body: {
				bg: 'black',
				m: 0,
				p: 0,
			},
			p: {
				color: 'white',
			},
			b: {
				color: 'white',
			},
		},
	},
    colors: {
        custom: {
            background: "#2e3440",
			navbar: "#4c566a",
			container: "#3b4252",
			orange: "#f6721f",
            text: "#d8dee9",
			border: "#6a707a"
        },
    },
	fonts: {
		body: 'Maven Pro, sans-serif',
		heading: 'Maven Pro, sans-serif',
	},
};

export default extendTheme(theme);
